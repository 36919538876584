<template>
    <!-- eslint-disable max-len -->
    <div class="JuneaWalletCard relative rounded-3xl">
        <div class="JuneaWalletCard_Inner absolute inset-0 p-6 flex flex-col justify-between">
            <div class="JuneaWalletCard_Top flex justify-between">
                <div class="JuneaWalletCard_Logo flex items-center text-white">
                    <SVGLogo height="36" />
                    <h3 class="opacity-50 ml-1 mt-2 font-medium text-white">Wallet</h3>
                </div>

                <!-- Settings button -->
                <button @click="toggleWalletOptionsDrawer">
                    <AppIcon icon-name="ellipsis-horizontal" class="text-white" :width="24" :height="24" />
                </button>
            </div>

            <div class="JuneaWalletCard_Bottom flex items-end justify-between">
                <div class="JuneaWalletCard_Balance">
                    <span class="block opacity-50 text-white">{{ $t('account.wallet.balance') }}</span>
                    <span class="block text-xl font-bold leading-7 text-white">{{ currency(walletBalance) }}</span>
                </div>

                <button v-if="isConnected && walletBalance < maxBalanceValue" @click="toggleWalletTopUpDrawer" class="JuneaWalletCard_TopUp flex items-center rounded-full">
                    <AppIcon icon-name="plus" class="text-white" />
                    <span class="ml-1 font-medium text-white">{{ $t('account.wallet.top-up') }}</span>
                </button>
            </div>
        </div>

        <portal to="drawers">
            <CheckoutDrawer
                v-if="showCheckoutDrawer && topUpAmount && topUpAmount > 0"
                :amount="topUpAmount"
                :allow-junea-wallet="false"
                :is-topup-payment="true"
                @payment-method-picked="paymentMethodPicked($event)"
                @ideal-bank-selected="idealBankSelected($event)"
                @cancel="cancelCheckout" />

            <WalletOptionsDrawer
                v-if="walletOptionsDrawerOpen"
                :allow-top-up="isConnected && walletBalance < maxBalanceValue"
                @top-up="toggleWalletTopUpDrawer"
                @close="toggleWalletOptionsDrawer" />

            <JuneaWalletTopUpDrawer
                v-if="walletTopUpDrawerActive"
                :wallet-balance="walletBalance"
                :max-balance-value="maxBalanceValue"
                @amount-picked="openPaymentMethodDrawer($event)"
                @cancel="toggleWalletTopUpDrawer" />
         </portal>
    </div>
</template>

<script>
/* eslint-disable max-len, object-curly-newline */
import JuneaWalletTopUpDrawer from '@/components/Drawers/JuneaWalletTopUp.vue';
import CheckoutDrawer from '@/components/Drawers/Checkout.vue';
import WalletOptionsDrawer from '@/components/Drawers/WalletOptions.vue';
import SVGLogo from '@/assets/svg/logo.svg?inline';
import AppIcon from '@/components/Interface/AppIcon.vue';
// import AppAvatar from '@/components/Interface/AppAvatar.vue';
import { reactive, ref, toRefs, onBeforeMount } from '@vue/composition-api';
import useWallet from '@/composables/useWallet';
import useFilters from '@/filters';
import useConnection from '@/composables/useConnection';

export default {
    name: 'JuneaWalletCard',

    props: {
        compact: Boolean,
    },

    components: {
        JuneaWalletTopUpDrawer,
        CheckoutDrawer,
        WalletOptionsDrawer,
        SVGLogo,
        AppIcon,
        // AppAvatar,
    },

    setup(_, { root }) {
        const { getWalletBalance, topUpWalletBalance, maxBalanceValue } = useWallet();
        const { currency } = useFilters();
        const { isConnected } = useConnection();

        const walletBalance = ref(null);

        onBeforeMount(async () => {
            walletBalance.value = await getWalletBalance();
        });

        const juneaWalletData = reactive({
            walletTopUpDrawerActive: false,
            showCheckoutDrawer: false,
            bankSelectDrawerActive: false,
            topUpAmount: null,
            paymentMethod: null,
        });

        /**
         * Toggle the wallet top up drawer
         * @returns {void}
         */
        const toggleWalletTopUpDrawer = () => {
            juneaWalletData.walletTopUpDrawerActive = !juneaWalletData.walletTopUpDrawerActive;
        };

        /**
         * Open the payment method selector drawer
         * @return {void}
         */
        const openPaymentMethodDrawer = (amount) => {
            juneaWalletData.topUpAmount = amount.value;
            juneaWalletData.showCheckoutDrawer = true;
        };

        /**
         * Method to toggle the iDeal bank selector method drawer
         * @returns {void}
         */
        const toggleBankSelectDrawer = () => {
            juneaWalletData.bankSelectDrawerActive = !juneaWalletData.bankSelectDrawerActive;
        };

        /**
         * After a payment method was picked, continue to checkout
         * @returns {void}
         */
        const paymentMethodPicked = (paymentMethod) => {
            juneaWalletData.paymentMethod = paymentMethod;

            // Show bank selector drawer if payment method is iDeal
            if (paymentMethod === 'ideal') {
                toggleBankSelectDrawer();
            } else {
                topUpWalletBalance(juneaWalletData.topUpAmount, paymentMethod);

                // Close drawers
                toggleWalletTopUpDrawer();
                juneaWalletData.showCheckoutDrawer = false;
            }
        };

        /**
         * Callback after iDeal bank was select
         * @returns {void}
         */
        const idealBankSelected = (idealBank) => {
            topUpWalletBalance(juneaWalletData.topUpAmount, juneaWalletData.paymentMethod, idealBank);

            // Close all checkout drawers
            // toggleBankSelectDrawer();
            // juneaWalletData.showCheckoutDrawer = false;
            // toggleWalletTopUpDrawer();
        };

        /**
         * Cancel the checkout proces and close the drawers
         * @returns {void}
         */
        const cancelCheckout = () => {
            juneaWalletData.showCheckoutDrawer = false;
            juneaWalletData.topUpAmount = null;
        };

        // Hotlink for opening wallet topup
        if (root.$router.currentRoute.query.topup) {
            toggleWalletTopUpDrawer();
        }

        // Wallet options drawer
        const walletOptionsDrawerOpen = ref(false);

        /**
         * Method to toggle the wallet options drawer
         * @returns {void}
         */
        const toggleWalletOptionsDrawer = () => {
            walletOptionsDrawerOpen.value = !walletOptionsDrawerOpen.value;
        };

        return {
            ...toRefs(juneaWalletData),
            walletBalance,
            maxBalanceValue,
            currency,
            isConnected,
            toggleWalletTopUpDrawer,
            openPaymentMethodDrawer,
            paymentMethodPicked,
            toggleBankSelectDrawer,
            idealBankSelected,
            cancelCheckout,
            walletOptionsDrawerOpen,
            toggleWalletOptionsDrawer,
        };
    },
};
</script>

<style lang="scss" scoped>
.JuneaWalletCard {
    padding-top: 63%; // 1:1.586 aspect ratio

    background: linear-gradient(
        224.28deg, rgba(255, 255, 255, 0.216)
        8.44%, rgba(255, 255, 255, 0.12)
        16.44%, rgba(255, 255, 255, 0.216)
        28.83%, rgba(255, 255, 255, 0) 40.91%
    ), #ED4A68;

    &_TopUp {
        background: rgba(0, 0, 0, 0.04);
        padding: 14px;
    }
}
</style>
