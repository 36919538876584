<template>
    <!-- eslint-disable max-len -->
    <BaseDrawer @close="$emit('close')" class="WalletOptionsDrawer">
        <div class="grid row-gap-4 pb-8">
            <button v-if="allowTopUp" @click="$emit('top-up')">
                <AppMenuItem :label="$t('account.wallet.options.top-up')">
                    <template #icon><AppIcon icon-name="deposit" :width="24" :height="24" /></template>
                </AppMenuItem>
            </button>

            <button @click="requestRefundDrawerOpen = !requestRefundDrawerOpen">
                <AppMenuItem :label="$t('account.wallet.options.refund')">
                    <template #icon><AppIcon icon-name="withdraw" :width="24" :height="24" /></template>
                </AppMenuItem>
            </button>
        </div>

        <portal to="drawers">
            <RequestRefundDrawer
                v-if="requestRefundDrawerOpen"
                :balance="user.wallet"
                @close="requestRefundDrawerOpen = !requestRefundDrawerOpen" />
         </portal>
    </BaseDrawer>
</template>

<script>
import BaseDrawer from '@/components/Interface/BaseDrawer.vue';
import RequestRefundDrawer from '@/components/Drawers/RequestRefund.vue';
import AppMenuItem from '@/components/Interface/AppMenuItem.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import useAuth from '@/composables/useAuth';
import { ref } from '@vue/composition-api';

export default {
    name: 'WalletOptionsDrawer',

    props: {
        allowTopUp: Boolean,
    },

    components: {
        BaseDrawer,
        RequestRefundDrawer,
        AppMenuItem,
        AppIcon,
    },

    setup() {
        const { user } = useAuth();

        // Request refund drawer
        const requestRefundDrawerOpen = ref(false);

        return {
            user,
            requestRefundDrawerOpen,
        };
    },
};
</script>

<style lang="scss" scoped>
.WalletOptionsDrawer {}
</style>
