<template>
    <!-- eslint-disable max-len -->
    <div class="user-account-OrderHistory bg-day-01dp dark:bg-night-01dp h-full overflow-y-auto">
        <TheDefaultHeader :back="true" />

        <main class="user-account-OrderHistory_Content mx-4">
            <header class="user-account-OrderHistory_Header flex justify-center items-center mb-6">
                <AppIcon icon-name="receipt" :width="24" :height="24" />
                <h1 class="ml-2">{{ $t('account.order-history.heading') }}</h1>
            </header>

            <div class="user-account-OrderHistory_Orders grid gap-4">
                <AccountOrderItem v-for="order in orderHistory" :key="`order-${order.id}`" :order="order" />
            </div>
        </main>

        <TheBottomMenu />
    </div>
</template>

<script>
import TheDefaultHeader from '@/components/Global/TheDefaultHeader.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import AccountOrderItem from '@/components/Account/OrderItem.vue';
import TheBottomMenu from '@/components/Global/TheBottomMenu.vue';
import { ref, onBeforeMount } from '@vue/composition-api';
import router from '@/router';

export default {
    name: 'UserAccountOrderHistory',

    components: {
        TheDefaultHeader,
        AppIcon,
        AccountOrderItem,
        TheBottomMenu,
    },

    setup(_, { root }) {
        const orderHistory = ref(null);

        /**
         * Method to fetch order history data
         *
         * @async
         * @returns {void}
         */
        const fetchOrderHistory = async () => {
            try {
                const response = await root.$http.get('/account/order-history');
                orderHistory.value = response.data.data;
            } catch (error) {
                router.push({ name: 'ServerError' });
            }
        };

        onBeforeMount(async () => {
            await fetchOrderHistory();
        });

        return {
            orderHistory,
        };
    },
};
</script>

<style lang="scss" scoped>
.user-account-OrderHistory {

    &_Content {
        margin-bottom: 84px; // Dock menu + toolbar
    }
}
</style>
