<template>
    <!-- eslint-disable max-len -->
    <BaseDrawer @close="$emit('close')" class="RequestRefundDrawer">
        <div class="px-4">
            <header class="AuthCreateAccountDrawer_Header">
                <h1 class="mb-1">{{ $t('account.wallet.refund.title') }}</h1>
                <p @click="$emit('show-login')" v-html="$t('account.wallet.refund.description')" class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis mb-1"></p>
            </header>

            <ValidationObserver v-slot="{ handleSubmit }" immediate slim>
                <form @submit.prevent="handleSubmit(submitRequest)" novalidate class="AuthCreateAccountDrawer_Form mt-4 mb-8 grid row-gap-4">
                    <ValidationProvider rules="required" v-slot="{ errors, valid }" mode="eager" slim>
                        <AppInputField v-model="iban" :label="$t('account.wallet.refund.fields.iban')" placeholder="e.g. NL91ABNA0417164300" :error="errors[0]" :valid="valid" />
                    </ValidationProvider>

                    <ValidationProvider rules="required" v-slot="{ errors, valid }" mode="eager" slim>
                        <AppInputField v-model="receiver" :label="$t('account.wallet.refund.fields.receiver')" placeholder="" :error="errors[0]" :valid="valid" />
                    </ValidationProvider>

                    <AppInputField v-model="reason" :label="$t('account.wallet.refund.fields.reason')" />

                    <ValidationProvider :rules="{ required: true, max_value: balance }" v-slot="{ errors, valid }" mode="eager" slim>
                        <AppInputField v-model="amount" :label="$t('account.wallet.refund.fields.amount', { values: { max: balance } })" placeholder="" :error="errors[0]" :valid="valid" />
                    </ValidationProvider>

                    <AppButton size="small" width="full" class="mt-6">{{ $t('account.wallet.refund.button') }}</AppButton>
                </form>
            </ValidationObserver>
        </div>
    </BaseDrawer>
</template>

<script>
import BaseDrawer from '@/components/Interface/BaseDrawer.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import AppInputField from '@/components/Interface/AppInputField.vue';
import { reactive, toRefs } from '@vue/composition-api';

export default {
    name: 'RequestRefundDrawer',

    props: {
        balance: Number,
    },

    components: {
        BaseDrawer,
        AppButton,
        AppInputField,
    },

    setup(_, { root, emit }) {
        const requestRefundData = reactive({
            iban: '',
            receiver: '',
            reason: '',
            amount: '',
        });

        const submitRequest = async () => {
            try {
                await root.$http.post('/wallet/refund-request', {
                    iban: requestRefundData.iban,
                    receiver: requestRefundData.receiver,
                    reason: requestRefundData.reason,
                    amount: requestRefundData.amount,
                });

                root.$toast.open({
                    message: root.$t('Gelukt! We hebben je aanvraag ontvangen en nemen deze in behandeling.'),
                    type: 'success',
                    duration: 10000,
                });

                emit('close');
            } catch (error) {
                requestRefundData.error = true;
                root.$toast.open({
                    message: root.$t('Er is iets misgegaan. Controleer de velden en probeer het opnieuw.'),
                    type: 'warning',
                    duration: 8000,
                });
            }

            return true;
        };

        return {
            ...toRefs(requestRefundData),
            submitRequest,
        };
    },
};
</script>

<style lang="scss" scoped>
.RequestRefundDrawer {}
</style>
