<template>
    <!-- eslint-disable max-len -->
    <div class="user-account-OrderHistoryDetail bg-day-01dp dark:bg-night-01dp h-full overflow-y-auto">
        <TheDefaultHeader :back="true" />

        <main v-if="order" class="user-account-OrderHistoryDetail_Content mx-4">
            <div class="user-account-OrderHistoryDetail_Machine text-center mt-4">
                <img src="@/assets/images/machine-types/vending.png" :alt="order.machine" class="MachineSliderItem_Image-product mx-auto mb-3 h-24" />

                <h2 class="text-day-on-surface-high-emphasis dark:text-night-on-surface-high-emphasis">{{ order.machine }}</h2>

                <div class="flex items-center justify-center text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis dark:text-white">
                    <div class="flex items-center mr-4">
                        <AppIcon icon-name="marker" :width="16" :height="16" />
                        <span class="ml-1">{{ order.location }}</span>
                    </div>

                    <div class="flex items-center">
                        <AppIcon icon-name="history" :width="16" :height="16" />
                        <span class="ml-1">{{ timeFromNow(order.created_at) }}</span>
                    </div>
                </div>
            </div>

            <div class="user-account-OrderHistoryDetail_Products mt-8 grid row-gap-2">
                <OrderProductListItem
                    v-for="(orderItem, index) in order.items"
                    :key="`product-${index}`"
                    :slot-data="orderItem"
                    :product="orderItem.product"
                    :allow-add="false" />
            </div>

            <div class="user-account-OrderHistoryDetail_Totals mt-6">
                <!-- <div class="text-day-on-surface-low-emphasis-disabled dark:text-night-on-surface-low-emphasis-disabled flex justify-between items-center">
                    <span>{{ $t('account.order-history.transaction-costs') }}</span>
                    <span>+ {{ currency(order.transaction_costs) }}</span>
                </div> -->

								<div v-if="order.total_deposit" class="flex justify-between items-center">
									<span class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ $t("order.order-overview.deposit") }}</span>
									<span class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ currency(order.total_deposit) }}</span>
								</div>

                <div class="flex justify-between items-center">
                    <h3>{{ $t('account.order-history.total-price') }}:</h3>
                    <h3>{{ currency(order.costs) }}</h3>
                </div>
            </div>

            <div class="user-account-OrderHistoryDetail_PaymentMethod mt-2 flex justify-center">
                <div class="inline-block px-3 py-2 bg-day-12dp dark:bg-night-12dp rounded-lg">
                    <span class="font-medium">{{ $t('account.order-history.paid-via') }} <span class="uppercase">{{ order.payment_method }}</span></span>
                </div>
            </div>
        </main>

        <TheBottomMenu />
    </div>
</template>

<script>
/* eslint-disable max-len */
import TheDefaultHeader from '@/components/Global/TheDefaultHeader.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import OrderProductListItem from '@/components/Order/ProductListItem.vue';
import TheBottomMenu from '@/components/Global/TheBottomMenu.vue';
import useFilters from '@/filters';
import { onBeforeMount, ref } from '@vue/composition-api';
import router from '@/router';

export default {
    name: 'UserAccountOrderHistoryDetail',

    components: {
        TheDefaultHeader,
        AppIcon,
        OrderProductListItem,
        TheBottomMenu,
    },

    setup(_, { root }) {
        const { timeFromNow, currency } = useFilters();

        const order = ref(null);

        /**
         * Method to fetch order history data
         *
         * @async
         * @returns {void}
         */
        const fetchOrderData = async () => {
            try {
                const response = await root.$http.get('/account/order-history');
                const orderResult = response.data.data.find((item) => item.id === Number(root.$route.params.id));

                if (!orderResult) {
                    throw new Error('Order niet gevonden', { code: 404 });
                }

                order.value = orderResult;
            } catch (error) {
                if (error.response.status === 404) {
                    router.push({ name: 'OrderHistory' });
                } else {
                    // router.push({ name: 'ServerError' });
                }
            }
        };

        onBeforeMount(async () => {
            await fetchOrderData();
        });

        return {
            timeFromNow,
            currency,
            order,
        };
    },
};
</script>

<style lang="scss" scoped>
.user-account-OrderHistoryDetail {

    &_Content {
        margin-bottom: 84px; // Dock menu + toolbar
    }
}
</style>
