<template>
    <!-- eslint-disable max-len -->
    <BaseDrawer @close="$emit('cancel')" class="JuneaWalletTopUpDrawer">
        <header class="JuneaWalletTopUpDrawer_Header text-center">
            <h1 class="mb-1">{{ $t('account.wallet.top-up-drawer.heading') }}</h1>
            <span class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis"><span class="font-medium">{{ $t('account.wallet.top-up-drawer.current-balance') }}: {{ currency(walletBalance) }}</span> / {{ currency(maxBalanceValue) }}</span>
        </header>

        <div class="JuneaWalletTopUpDrawer_Values mt-4">
            <div v-for="value in values" :key="`value-${value}`"
                @click="selectValue(value)"
                class="py-4 text-center rounded-xl cursor-pointer"
                :class="{ 'bg-day-04dp dark:bg-night-03dp' :selectedValue === value }">

                <span class="text-3xl leading-9 font-medium tracking-tighter">{{ currency(value) }}</span>
            </div>
        </div>

        <div class="JuneaWalletTopUpDrawer_Actions mt-4 grid grid-cols-2 col-gap-2">
            <AppButton type="secondary" @click="$emit('cancel')">{{ $t('account.wallet.top-up-drawer.button-cancel') }}</AppButton>
            <AppButton @click="continueToCheckout">{{ $t('account.wallet.top-up-drawer.button-continue') }}</AppButton>
        </div>
    </BaseDrawer>
</template>

<script>
/* eslint-disable max-len */
import BaseDrawer from '@/components/Interface/BaseDrawer.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import { ref } from '@vue/composition-api';
import useFilters from '@/filters';

export default {
    name: 'JuneaWalletTopUpDrawer',

    components: {
        BaseDrawer,
        AppButton,
    },

    props: {
        walletBalance: Number,
        maxBalanceValue: Number,
    },

    setup(props, { emit }) {
        const { currency } = useFilters();

        const values = [5, 10, 15, 20, 25].filter((value) => (value + props.walletBalance) <= props.maxBalanceValue);
        const selectedValue = ref(5);

        if (props.maxBalanceValue && !values.includes(props.maxBalanceValue - props.walletBalance)) {
            const maxCustomValue = props.maxBalanceValue - props.walletBalance;
            values.push(maxCustomValue);

            if (maxCustomValue <= values[0]) {
                selectedValue.value = maxCustomValue;
            }
        }

        /**
         * Select a top up value
         *
         * @param {Number} value
         * @returns {void}
         */
        const selectValue = (value) => {
            selectedValue.value = value;
        };

        /**
         * Method to emit amount picked event
         *
         * @returns {void}
         */
        const continueToCheckout = () => {
            emit('amount-picked', selectedValue);
        };

        return {
            currency,
            values,
            selectedValue,
            selectValue,
            continueToCheckout,
        };
    },
};
</script>

<style lang="scss">
.JuneaWalletTopUpDrawer {

    .ui-BaseDrawer_Inner {
        background-color: theme('colors.day.01dp');
    }
}

.mode-dark {

    .JuneaWalletTopUpDrawer {

        .ui-BaseDrawer_Inner {
            background-color: theme('colors.night.01dp');
        }
    }
}
</style>
