<template>
    <!-- eslint-disable max-len -->
    <div class="user-account-NoAccount flex flex-col overflow-y-auto bg-day-01dp dark:bg-night-01dp">
        <TheOrderHeader v-if="connectedToMachine" />
        <TheDefaultHeader v-else />

        <main class="user-account-NoAccount_Content flex-grow flex flex-col flex-shrink-0 justify-center px-8">

            <!-- Create account block -->
            <div @click="toggleRegisterDrawer" class="user-account-NoAccount_Register flex-grow rounded-2xl p-4 relative cursor-pointer overflow-hidden" :style="{ backgroundColor: '#FABF3F' }">
                <div class="flex items-center relative z-10">
                    <h1 class="mr-1 text-black">{{ $t('account.no-account.heading') }} <AppIcon icon-name="arrow-right" class="text-primary-brand inline-block h-5" :width="24" :height="24" /></h1>
                </div>

                <p v-html="$t('account.no-account.description')" class="relative z-10 text-day-on-surface-medium-emphasis"></p>

                <img :src="require(`@/assets/images/register-images/yellow.jpg`)" class="absolute bottom-0 inset-x-0 w-full h-auto max-h-full rounded-2xl object-cover" />
            </div>

            <!-- Login block -->
            <div @click="toggleLoginDrawer" class="user-account-NoAccount_Login rounded-2xl bg-day-04dp dark:bg-night-08dp mt-4 p-4 cursor-pointer">
                <div class="flex items-center">
                    <h1 class="mr-1">{{ $t('account.no-account.button-login') }}</h1>
                    <AppIcon icon-name="arrow-right" class="text-primary-brand" :width="24" :height="24" />
                </div>

                <p class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">{{ $t('account.no-account.already-registered') }}</p>
            </div>
        </main>

        <TheBottomMenu />

        <portal to="drawers">
            <AuthLoginDrawer
                v-if="loginDrawerActive"
                @close="toggleLoginDrawer"
                @show-register="toggleRegisterDrawer"
                @forgot-password="toggleResetPasswordDrawer" />

            <AuthCreateAccountDrawer
                v-if="registerDrawerActive"
                @close="toggleRegisterDrawer"
                @show-login="toggleLoginDrawer" />

            <AuthResetPasswordDrawer
                v-if="resetPasswordDrawerActive"
                @close="toggleResetPasswordDrawer"
                @show-login="toggleLoginDrawer" />
        </portal>
    </div>
</template>

<script>
import TheDefaultHeader from '@/components/Global/TheDefaultHeader.vue';
import TheOrderHeader from '@/components/Global/TheOrderHeader.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import AuthLoginDrawer from '@/components/Auth/LoginDrawer.vue';
import AuthCreateAccountDrawer from '@/components/Auth/CreateAccountDrawer.vue';
import AuthResetPasswordDrawer from '@/components/Auth/ResetPasswordDrawer.vue';
import TheBottomMenu from '@/components/Global/TheBottomMenu.vue';
import { ref } from '@vue/composition-api';
import useMachine from '@/composables/useMachine';

export default {
    name: 'UserAccountNoAccount',

    components: {
        TheDefaultHeader,
        TheOrderHeader,
        AppIcon,
        AuthLoginDrawer,
        AuthCreateAccountDrawer,
        AuthResetPasswordDrawer,
        TheBottomMenu,
    },

    setup(_, { root }) {
        const { machine: connectedToMachine } = useMachine();

        const loginDrawerActive = ref(false);
        const registerDrawerActive = ref(false);
        const resetPasswordDrawerActive = ref(false);

        if (root.$route.query.token) {
            resetPasswordDrawerActive.value = true;
        }

        /**
         * Method to toggle the login drawer
         * @returns {void}
         */
        const toggleLoginDrawer = () => {
            registerDrawerActive.value = false;
            resetPasswordDrawerActive.value = false;
            loginDrawerActive.value = !loginDrawerActive.value;
        };

        /**
         * Method to toggle the register drawer
         * @returns {void}
         */
        const toggleRegisterDrawer = () => {
            loginDrawerActive.value = false;
            registerDrawerActive.value = !registerDrawerActive.value;
        };

        /**
         * Method to toggle the password reset drawer
         * @returns {void}
         */
        const toggleResetPasswordDrawer = () => {
            loginDrawerActive.value = false;
            resetPasswordDrawerActive.value = !resetPasswordDrawerActive.value;
        };

        return {
            connectedToMachine,
            loginDrawerActive,
            registerDrawerActive,
            resetPasswordDrawerActive,
            toggleLoginDrawer,
            toggleRegisterDrawer,
            toggleResetPasswordDrawer,
        };
    },
};
</script>

<style lang="scss">
.user-account-NoAccount {
    height: 100%;

    .layout-TheDefaultHeader {
        @apply bg-day-01dp;
    }

    &_Content {
        padding-bottom: 96px; // Dock menu + toolbar
    }

    &_Register {
        padding-right: 35%;
    }
}

.mode-dark .user-account-NoAccount {

    .layout-TheDefaultHeader,
    .layout-TheOrderHeader {
        @apply bg-night-01dp;
    }
}
</style>
