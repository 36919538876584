<template>
    <!-- eslint-disable max-len -->
    <div class="UserAccount bg-day-01dp dark:bg-night-01dp h-full overflow-y-auto">
        <TheOrderHeader v-if="connectedToMachine" />
        <TheDefaultHeader v-else />

        <main class="UserAccount_Content mx-4">
            <JuneaWalletCard />

            <nav class="UserAccount_Menu grid row-gap-2 mt-5">
                <RouterLink :to="{ name: 'OrderHistory' }">
                    <AppMenuItem :label="$t('account.menu.order-history')">
                        <template #icon><SVGMenuOrder /></template>
                    </AppMenuItem>
                </RouterLink>

                <RouterLink :to="{ name: 'EditProfile' }">
                    <AppMenuItem :label="$t('account.menu.my-profile')">
                        <template #icon><SVGProfile /></template>

                        <template v-if="profileIncomplete" #right>
                            <AppBadge class="bg-day-04dp dark:bg-night-on-surface">
                                {{ $t('account.menu.incomplete') }}
                            </AppBadge>
                        </template>
                    </AppMenuItem>
                </RouterLink>

                <!-- <AppMenuItem label="Payment methods">
                    <template #icon><SVGPaymentMethods /></template>
                </AppMenuItem> -->

                <!-- <AppMenuItem @click="toggleNewsLetter" :label="$t('account.menu.newsletter')">
                    <template #icon><SVGNewsletter /></template>

                    <template #right>
                        <AppSwitch v-model="newsLetterEnabled" />
                    </template>
                </AppMenuItem> -->

                <AppMenuItem @click="logout" :label="$t('account.menu.sign-out')">
                    <template #icon><SVGSignout /></template>
                </AppMenuItem>
            </nav>
        </main>

        <TheBottomMenu />
    </div>
</template>

<script>
import TheDefaultHeader from '@/components/Global/TheDefaultHeader.vue';
import TheOrderHeader from '@/components/Global/TheOrderHeader.vue';
import JuneaWalletCard from '@/components/Account/JuneaWalletCard.vue';
import AppMenuItem from '@/components/Interface/AppMenuItem.vue';
// import AppSwitch from '@/components/Interface/AppSwitch.vue';
import AppBadge from '@/components/Interface/AppBadge.vue';
import TheBottomMenu from '@/components/Global/TheBottomMenu.vue';
import SVGMenuOrder from '@/assets/svg/menu-order.svg?inline';
import SVGProfile from '@/assets/svg/menu-profile.svg?inline';
// import SVGPaymentMethods from '@/assets/svg/menu-payment-methods.svg?inline';
// import SVGNewsletter from '@/assets/svg/menu-newsletter.svg?inline';
import SVGSignout from '@/assets/svg/menu-signout.svg?inline';
import { computed, ref } from '@vue/composition-api';
import useAuth from '@/composables/useAuth';
import useMachine from '@/composables/useMachine';

const useNewsletter = () => {
    const newsLetterEnabled = ref(false);

    const toggleNewsLetter = () => {
        newsLetterEnabled.value = !newsLetterEnabled.value;
    };

    return {
        newsLetterEnabled,
        toggleNewsLetter,
    };
};

export default {
    name: 'UserAccount',

    components: {
        TheDefaultHeader,
        TheOrderHeader,
        JuneaWalletCard,
        AppMenuItem,
        // AppSwitch,
        AppBadge,
        TheBottomMenu,
        SVGMenuOrder,
        SVGProfile,
        // SVGPaymentMethods,
        // SVGNewsletter,
        SVGSignout,
    },

    setup() {
        const { logout, user } = useAuth();
        const { machine: connectedToMachine } = useMachine();

        const profileIncomplete = computed(() => !user.value?.phone_number);

        return {
            ...useNewsletter(),
            profileIncomplete,
            logout,
            connectedToMachine,
        };
    },
};
</script>

<style lang="scss" scoped>
.UserAccount {

    &_Content {
        margin-bottom: 84px; // Dock menu + toolbar
    }
}

.mode-dark .UserAccount {

    .layout-TheDefaultHeader,
    .layout-TheOrderHeader {
        @apply bg-night-01dp;
    }
}
</style>
