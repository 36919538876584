<template>
    <!-- eslint-disable max-len -->
    <div @click="openOrderDetail" class="AccountOrderItem p-4 bg-day-04dp dark:bg-night-04dp flex items-center justify-between rounded-3xl">
        <div class="AccountOrderItem_Left flex items-center">
            <div class="AccountOrderItem_Image">
                <img src="@/assets/images/machine-types/vending.png" :alt="order.machine" class="w-12" />
            </div>

            <div class="AccountOrderItem_Info mx-2">
                <h3 class="text-day-on-surface dark:text-white font-medium">{{ order.machine }}</h3>

                <div class="flex items-center text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">
                    <span class="mr-2">{{ currency(order.costs) }}</span>

                    <AppIcon icon-name="history" :width="16" :height="16" />
                    <span class="font-normal" style="margin-left: 2px;">{{ timeFromNow(order.created_at) }}</span>
                </div>
            </div>
        </div>

        <AppButton @click.stop="openOrderDetail" type="secondary" size="icon" class="AccountOrderItem_Button">
            <AppIcon icon-name="arrow-right" key="connect-machine" :width="24" :height="24" />
        </AppButton>
    </div>
</template>

<script>
import AppButton from '@/components/Interface/AppButton.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import router from '@/router';
import useFilters from '@/filters';

export default {
    name: 'AccountOrderItem',

    props: {
        order: {
            required: true,
            type: Object,
        },
    },

    components: {
        AppButton,
        AppIcon,
    },

    setup(props) {
        const { timeFromNow, currency } = useFilters();

        /**
         * Navigate to the order detail page
         * @returns {void}
         */
        const openOrderDetail = () => {
            router.push({
                name: 'OrderHistoryDetail',
                params: {
                    id: props.order.id,
                },
            });
        };

        return {
            timeFromNow,
            currency,
            openOrderDetail,
        };
    },
};
</script>

<style lang="scss" scoped>
.AccountOrderItem {

    &_Button {
        background-color: theme('colors.day.on-primary');
    }
}

.mode-dark .AccountOrderItem {

    &_Button {
        background-color: theme('colors.night.08dp');
    }
}
</style>
