<template>
    <!-- eslint-disable max-len -->
    <div class="user-account-EditProfile bg-day-01dp dark:bg-night-01dp h-full overflow-y-auto">
        <TheDefaultHeader :back="true" />

        <ValidationObserver v-slot="{ handleSubmit }" immediate slim>
            <form @submit.prevent="handleSubmit(safeProfile)" novalidate class="user-account-EditProfile_Form mx-4 grid row-gap-2">
                <!-- <div class="user-account-EditProfile_Avatar mb-3 flex justify-center">
                    <AppAvatar image="/img/dummy-avatar.png" size="large" />

                    <div class="user-account-EditProfile_AvatarUpload relative z-10">
                        <div class="bg-primary-brand cursor-pointer rounded-full p-2 -ml-8">
                            <AppIcon icon-name="pencil" :width="20" :height="20" class="text-white" />
                        </div>

                        <input type="file" name="avatar" class="absolute -ml-8 w-10 h-10 inset-0 opacity-0" />
                    </div>
                </div> -->

                <div class="grid grid-cols-2 col-gap-4">
                    <ValidationProvider rules="required|min:3" v-slot="{ errors, valid }" mode="eager" slim>
                        <AppInputField
                            v-model="firstName"
                            :label="$t('account.profile.firstname')"
                            placeholder="e.g. John"
                            :error="errors[0]"
                            :disabled="!isConnected"
                            :valid="valid"
                            @focus="toggleKeyboardActive"
                            @blur="toggleKeyboardActive" />
                    </ValidationProvider>

                    <ValidationProvider rules="required|min:3" v-slot="{ errors, valid }" mode="eager" slim>
                        <AppInputField
                            v-model="lastName"
                            :label="$t('account.profile.lastname')"
                            placeholder="e.g. Doe"
                            :error="errors[0]"
                            :disabled="!isConnected"
                            :valid="valid"
                            @focus="toggleKeyboardActive"
                            @blur="toggleKeyboardActive" />
                    </ValidationProvider>
                </div>

                <ValidationProvider rules="required|email" v-slot="{ errors, valid }" mode="eager" slim>
                    <AppInputField
                        v-model="email"
                        type="email"
                        :label="$t('account.profile.email')"
                        placeholder="e.g. john@email.com"
                        :error="errors[0]"
                        :disabled="!isConnected"
                        :valid="valid"
                        @focus="toggleKeyboardActive"
                        @blur="toggleKeyboardActive" />
                </ValidationProvider>

                <ValidationProvider rules="phone" v-slot="{ errors, valid }" mode="eager" slim>
                    <AppInputField
                        v-model="phone"
                        type="tel"
                        :label="$t('account.profile.phone')"
                        placeholder="e.g. 0612345678"
                        :error="errors[0]"
                        :disabled="!isConnected"
                        :valid="phone && valid && phone.length > 0"
                        @focus="toggleKeyboardActive"
                        @blur="toggleKeyboardActive" />
                </ValidationProvider>

                <ValidationProvider rules="date" v-slot="{ errors, valid }" slim>
                    <AppInputField
                        v-model="birthdate"
                        :label="$t('account.profile.birthdate')"
                        placeholder="dd-mm-yyyy"
                        :error="errors[0]"
                        mask="##-##-####"
                        :disabled="!isConnected"
                        :valid="birthdate && valid && birthdate.length > 0"
                        @focus="toggleKeyboardActive"
                        @blur="toggleKeyboardActive" />
                </ValidationProvider>

                <AppButton v-if="isConnected" size="small" width="full" class="mt-6">{{ $t('account.profile.button-save-changes') }}</AppButton>
            </form>
        </ValidationObserver>

        <TheBottomMenu v-if="!keyboardActive" />
    </div>
</template>

<script>
/* eslint-disable max-len, object-curly-newline, camelcase */
import TheDefaultHeader from '@/components/Global/TheDefaultHeader.vue';
import AppInputField from '@/components/Interface/AppInputField.vue';
import AppButton from '@/components/Interface/AppButton.vue';
// import AppAvatar from '@/components/Interface/AppAvatar.vue';
// import AppIcon from '@/components/Interface/AppIcon.vue';
import TheBottomMenu from '@/components/Global/TheBottomMenu.vue';
import { onBeforeMount, reactive, ref, toRefs } from '@vue/composition-api';
import useAuth from '@/composables/useAuth';
import useConnection from '@/composables/useConnection';
import router from '@/router';

export default {
    name: 'UserAccountEditProfile',

    components: {
        TheDefaultHeader,
        AppInputField,
        TheBottomMenu,
        // AppAvatar,
        // AppIcon,
        AppButton,
    },

    setup(props, { root }) {
        const { getUser } = useAuth();
        const { isConnected } = useConnection();

        const userData = reactive({
            firstName: null,
            lastName: null,
            email: null,
            phone: null,
            birthdate: null,
        });

        onBeforeMount(async () => {
            const { first_name, last_name, email, phone_number, date_of_birth } = await getUser();

            userData.firstName = first_name;
            userData.lastName = last_name;
            userData.email = email;
            userData.phone = phone_number;
            userData.birthdate = date_of_birth;
        });

        /**
         * Method to save current user
         * @returns {void}
         */
        const safeProfile = async () => {
            try {
                await root.$http.post('/account/profile', {
                    first_name: userData.firstName,
                    last_name: userData.lastName,
                    email: userData.email,
                    phone_number: userData.phone,
                    date_of_birth: userData.birthdate,
                });

                // Refetch user
                await getUser();

                router.push({ name: 'Account' });
            } catch (error) {
                let errorMessage = root.$t('account.profile.update-error-notification');
                const serverErrors = error.response.data.errors;

                // If there are server errors get the first one
                if (serverErrors && serverErrors[Object.keys(serverErrors)[0]]) {
                    const [firstErrorMessage] = serverErrors[Object.keys(serverErrors)[0]];
                    errorMessage = firstErrorMessage;
                }

                // Show error notification to user
                root.$toast.open({
                    message: errorMessage,
                    type: 'warning',
                });
            }
        };

        const keyboardActive = ref(false);

        const toggleKeyboardActive = () => {
            keyboardActive.value = !keyboardActive.value;
        };

        return {
            ...toRefs(userData),
            safeProfile,
            keyboardActive,
            toggleKeyboardActive,
            isConnected,
        };
    },
};
</script>

<style lang="scss" scoped>
.user-account-EditProfile {

    &_Form {
        margin-bottom: 84px; // Dock menu + toolbar
    }
}
</style>
